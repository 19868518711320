<template>
    <div class="modal-profile-item-selector bg-black">
        <QnaHeader :showLeftBtn="true" title="소득" />
        <p class="m-t-24 m-b-24 text-center c-white">서류 인증도 필요하니 정확한 소득을 입력해주세요.</p>
        <div class="container">
            <MoneyInput
                :initVal="initVal"
                @input="onChangeVal"
                @focus="needAddInfo = true"
                @blur="needAddInfo = false"
            />
        </div>
        <BottomButton @click="submit" />
    </div>
</template>

<script>
export default {
    name: 'ModalMoneyInput',
    data: () => ({
        initVal: 0,
        value: 0,
        needAddInfo: false,
    }),
    methods: {
        onClickClose() {
            this.$emit('close')
        },
        onChangeVal(val) {
            this.value = val
        },
        submit() {
            this.$emit('close', this.value)
        },
    },
}
</script>

<style scoped lang="scss">
.modal-profile-item-selector {
    //padding: 16px !important;
    .container {
        padding: 16px;
    }
    .back-button-wrapper {
        display: flex;
        flex-direction: row-reverse;
    }
}

::v-deep .money-input {
    .display {
        color: white;

        &.empty {
            color: $grey-06;
            @include f-medium;
        }
    }
}
::v-deep .bottom-button {
    background: transparent;
}
</style>
